import { RouterProvider } from 'react-router';
import { RecoilRoot } from 'recoil';
import root from '@/pc/router/root';
import rootMo from '@/mobile/router/rootMo';
import { createBrowserRouter } from 'react-router';

function App() {
  // 운영 테스트용 임시 배포 20250109
  console.log('Environment:', process.env.REACT_APP_ENV);
  console.log('API URL:', process.env.REACT_APP_API_URL);
  console.log('API URL:', process.env);
  
  const routes = createBrowserRouter([
    ...root, ...rootMo
  ]);

  return (
    <RecoilRoot>
      <RouterProvider router={routes} />
    </RecoilRoot>
  );
}

export default App;



